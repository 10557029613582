<nav class="navbar navbar-expand-md shadow mb-4" id="topbar">
    <div class="container-fluid">
        <a class="btn btn-collapse-side me-2" (click)="onToggleSideNav()">
            <fa-icon [icon]="faBars"></fa-icon>
        </a>
        <a class="btn btn-collapse-side me-2 d-md-none" routerLink="">
            <fa-icon [icon]="faArrow"></fa-icon>
        </a>

        <p-breadcrumb *ngIf="showBreadCrumb" class="admin-breadcrumb max-w-full d-none d-md-flex" [model]="menuItems"
            [home]="home"></p-breadcrumb>

        <ul class="navbar-nav ms-auto mb-lg-0">
            <ng-container *ngIf="auth.isAuthenticated$ | async;">
                <li class="nav-item dropdown no-arrow" *ngIf="auth.user$ | async as user">
                    <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        <span class="me-2 d-lg-inline">{{ user.name }}</span>
                        <img class="img-profile rounded-circle" title="profile-pic" src="{{ profilePicturePath }}" />
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end">
                        <li>
                            <a class="dropdown-item" routerLink="/">{{'app.ui.admin.menu.returnToSite' | translate}}</a></li>
                    </ul>
                </li>
            </ng-container>
        </ul>
    </div>

    <div class="topbar-divider d-none d-sm-block"></div>
</nav>